// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getVendor = createAsyncThunk('vendor/getVendor', async (id) => {
    return await axios
        .get('/vendor/profile/' + id)
        .then((res) => {
            return res?.data;
        })
        .catch((err) => {
            console.log('some errr', err?.response?.data?.message);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return null;
        });
});

export const vendor = createSlice({
    name: 'vendor',
    initialState: {
        vendor: null,
    },
    reducers: {
        handleVendorChange: (state, action) => {
            state = action.payload;
        },
        removeVendor: (state, action) => {
            state = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getVendor.fulfilled, (state, action) => {
            state.vendor = action.payload;
            return;
        });
    },
});

export const { handleVendorChange, removeVendor } = vendor.actions;

export default vendor.reducer;
