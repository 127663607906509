import React from 'react';
// import logo from '@src/assets/images/logo/logo.png';
import logo from '@src/assets/images/logo/ec-icon.png';

export const LoaderComponent = () => {
    return (
        <div style={{ position: 'fixed', zIndex: 1000000, background: 'rgb(248, 248, 248, 75%)' }}>
            <div id="loader" className="fallback-spinner app-loader " style={{ display: 'none', width: '100vw' }}>
                <img className="fallback-logo" src={logo} alt="logo" width={150} />
                <div className="loading">
                    <div className="effect-1 effects"></div>
                    <div className="effect-2 effects"></div>
                    <div className="effect-3 effects"></div>
                </div>
            </div>
        </div>
    );
};

const showLoader = (loading) => {
    const loader = document.getElementById('loader');
    if (loader) {
        if (loader && loading === true) {
            loader.classList.add('d-flex');
        } else {
            loader.classList.remove('d-flex');
        }
    }
};

export default showLoader;
