var service = {};

if (process?.env?.NODE_ENV === 'production') {
    service.API_URL = 'https://chq-api.teamfeeltech.com';
} else {
    // service.API_URL = 'http://192.168.29.253:2000';
    service.API_URL = 'https://chq-api.teamfeeltech.com';
}

export default service;
