import axios from 'axios';
import service from './constant';
import jwtService from '@src/auth/jwt/jwtService';
import { store } from '@store/store';
import { handleLogout } from '@store/authentication';
// import { getPermission } from '@store/permission';

// const history = useHistory();

const instance = axios.create({
    // baseURL: service.API_URL + '/api/v1',
    baseURL: service.API_URL + '/api',
});

// ** Request Interceptor
instance.interceptors.request.use(
    (config) => {
        // ** Get token from localStorage
        const accessToken = jwtService.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// ** Add request/response interceptor
instance.interceptors.response.use(
    (response) => response,
    (error) => {
        // ** const { config, response: { status } } = error
        const { response } = error; // ** if (status === 403)

        if (response && response.status === 403) {
            window.location.href = '/dashboard';
            // store.dispatch(getPermission());
        } // ** if (status === 401)

        // if (response && response.status === 401) {
        //     store.dispatch(handleLogout());
        //     window.location.href = '/login';
        // }

        return Promise.reject(error);
    }
);

export default instance;
