// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import vendor from './vendorProfile';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    vendor,
};

export default rootReducer;
