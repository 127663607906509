// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** jwtService import to get config
import jwtService from '@src/auth/jwt/jwtService';

// Loader Components
import showLoader from '@components/loader';

// API Imports
import axios from '@src/service/axios';
import { log } from '@craco/craco/lib/logger';

const config = jwtService.jwtConfig;

const initialUser = () => {
    const item = window.localStorage.getItem('userData');

    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

export const verifyToken = createAsyncThunk('authentication/verifyToken', async () => {
    showLoader(true);
    return await axios
        .get('/token', {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => {
            showLoader(false);
            return res?.data;
        })
        .catch((err) => {
            showLoader(false);
            toast.error('Session Expired!');
            return {};
        });
});

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser(),
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload.userData;
            state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName];

            // ** Remove user, accessToken from localStorage

            localStorage.setItem('userData', JSON.stringify(action?.payload?.userData));

            localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload[config.storageTokenKeyName]));
        },

        handleLogout: (state) => {
            state.userData = {};
            state[config.storageTokenKeyName] = null;

            // ** Remove user, accessToken from localStorage
            localStorage.removeItem('userData');
            localStorage.removeItem(config.storageTokenKeyName);
        },
    },

    extraReducers: (builder) => {
        builder.addCase(verifyToken.fulfilled, (state, action) => {
            state.userData = action.payload.userData;

            authSlice.caseReducers.handleLogin(state, action);
        });
    },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
